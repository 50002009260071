import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiParams, Paged, PagedParam, OltApiService } from '@outerlimitstech/ngx-app-core';
import { QuickSearchClient } from '../models/quick-search-client';
import { IMessageBusRequest } from 'bgcslib';

@Injectable({
  providedIn: 'root'
})
export class MatrixReportingService {

  constructor(
    protected apiService: OltApiService
  ) { }


  getMatrix(year: number, reportType: string): Observable<any> {
    return this.apiService.doGet(`/contracts/years/${year}/${reportType}/matrix`);
  }

  getMatrixDetail(year: number, reportType: string, filter: QuickSearchClient, pagingParameter: PagedParam): Observable<Paged<any>> {
    const params = new ApiParams();
    params.appendParams(filter.getAll());
    params.appendParams(pagingParameter.getAll());
    return this.apiService.doGet(`/contracts/years/${year}/${reportType}/matrix/detail`, params);
  }

  download(year: number, reportType: string, filter: QuickSearchClient): Observable<IMessageBusRequest> {
    const params = new ApiParams();
    params.appendParams(filter.getAll());
    return this.apiService.doGet(`/contracts/years/${year}/${reportType}/matrix/download`);
  }

}
