import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal, type OnInit } from '@angular/core';
import { BlobService } from '@app/core';
import { OltBaseViewComponent } from '@outerlimitstech/ngx-app-core';
import { SignalrService, ISignalrMessage, FileBase64Model, IMessageBusRequest, BROADCAST } from 'bgcslib';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter, switchMap, of, delay } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IModalReportGeneratingOptions {
  reportDescription: string;
  request: IMessageBusRequest | null;
}

@Component({
  standalone: false,
  templateUrl: './modal-report-generating.page.html',
  styleUrl: './modal-report-generating.page.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalReportGeneratingPage extends OltBaseViewComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  options: IModalReportGeneratingOptions ={
    reportDescription: 'Report',
    request: null
  };
  report: any | null = null;
  statusMessage = signal(`${this.options.reportDescription} Generating`);

  animateIcon = signal(true);
  generating = signal(true);

  private signalrService = inject(SignalrService);
  private blobService = inject(BlobService);
  public modal = inject(BsModalRef);

  ngOnInit(): void {
    this.modal.setClass('modal-sm');

    this.signalrService.messageBusMessage
      .pipe(
        filter((message: ISignalrMessage | null) => message?.action === BROADCAST.SignalR.MessageBus.BlobReady && message?.correlationId === this.options.request?.correlationId),
        switchMap((message: ISignalrMessage | null) => {
          this.report = message;
          this.statusMessage = signal(`${this.options.reportDescription} Downloading`);
          this.generating.set(false);
          return this.blobService.get(message?.data?.blobUid)
        }),
        switchMap((json: FileBase64Model) => {
          const blob = new FileBase64Model(json);
          blob.downloadFile();
          return of(null)
        }),
        delay(1500),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.animateIcon.set(false);
        this.statusMessage.set('Report Downloaded');
      });
  }

  download() {
    this.blobService.get(this.report?.data?.blobUid).subscribe(json => {
      const blob = new FileBase64Model(json);
      blob.downloadFile();
    })
  }

}
