export const BROADCAST = {
  Internal: {
  },
  SignalR: {
    MessageBus: {
      Unknown: "Unknown",
      FeatureFlagRefresh: 'FeatureFlagRefresh',
      BlobReady: 'BlobReady',
    }
  }
}
