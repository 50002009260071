<div class="modal-body text-center rounded p-4">
  <div class="mt-4 mb-4">

    @if (generating())
    {
      <div class="d-flex align-items-center justify-content-center">
        <div class="mr-2">
          <i class="fa-solid fa-spinner fa-spin-pulse fa-3x text-mentor-orange"></i>
        </div>
        <div>
          <h4>
            {{ statusMessage() }}
          </h4>
        </div>
      </div>
    }
    @else {
      <div class="d-flex align-items-center justify-content-center text-success-400">
        <div class="mr-2">
          @if (animateIcon()) {
            <i class="fa-solid fa-arrow-down fa-bounce fa-3x"></i>
          }
          @else {
            <i class="fa-sharp fa-solid fa-file-arrow-down fa-3x"></i>
          }
        </div>
        <div>
          <h4>
            {{ statusMessage() }}
          </h4>
        </div>
      </div>

      <div class="fst-italic mt-4">
        <p class="text-secondary mb-0">If your file did not download:</p>
        <a href="javascript:void(0);" (click)="download()">Click Here to download</a>
      </div>
    }
  </div>


  <button type="button" class="btn btn-primary" (click)="modal.hide()">
    Close
  </button>
</div>
