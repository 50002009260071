import { AppSettingsService, PrintService, StorageService } from '@app/core';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { OltBaseViewComponent, OltConfigServiceBase } from '@outerlimitstech/ngx-app-core';
import { BROADCAST, FeatureFlagService, ISignalrMessage, SignalrService } from 'bgcslib';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends OltBaseViewComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private featureFlagService = inject(FeatureFlagService);
  private appSettingsService = inject(AppSettingsService);
  private signalrService = inject(SignalrService);
  private storageService = inject(StorageService);


  private configService = inject(OltConfigServiceBase);
  private printService = inject(PrintService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private titleService = inject(Title);


  ngOnInit(): void {
    super.subscribeTitleChange(this.router, this.activatedRoute, this.titleService, this.configService);


    this.appSettingsService.appSetting$
    .pipe(
      filter((settings) => !!settings),
      switchMap(() => this.featureFlagService.initializeFlags())
    ).subscribe();



    this.signalrService.messageBusMessage
    .pipe(
      takeUntil(this.unsubscribe$),
      filter((message: ISignalrMessage | null) => message?.action === BROADCAST.SignalR.MessageBus.FeatureFlagRefresh),
    ).subscribe((message: ISignalrMessage | null) => {
      this.storageService.featureFlags = message.data.featureFlag;
    });

  }

  override ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
    super.ngOnDestroy();
  }

  get printingDocument(): boolean {
    return this.printService.isPrinting;
  }
}
