import { inject, Injectable } from '@angular/core';
import { OltApiService } from '@outerlimitstech/ngx-app-core';
import { FileBase64Model } from 'bgcslib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlobService {

  private apiService = inject(OltApiService);


  get(uid: string): Observable<FileBase64Model> {
    return this.apiService.doGet(`/general/blob/${uid}`);
  }


}
