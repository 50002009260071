import { IOltAppSettingResult } from "@outerlimitstech/ngx-app-core";
import { IAppSettings } from "bgcslib";
import { environment } from "environments/environment";

export const CONSTANTS = {
    Storage: {
        Token: 'bgcs.token',
        Username: 'bgcs.username',
        Permissions: 'bgcs.permissions',
        FeatureFlags: 'bgcs.feature.flags'
    },
    Application: {
        Name: 'bgcs',
        Title: 'Boys & Girls Clubs',
        SupportEmail: 'support@outerlimitstech.com'
    }
};


export const FALL_BACK_CONFIG: IOltAppSettingResult<IAppSettings> = {
    served: false,
    withError: null,
    settings: {
        hosts: [
            {
                host: "localhost",
                apiEndpoint: environment.apiRoot,
                environment: "Development",
                production: false
            }
        ]
    }
  };

